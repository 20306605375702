<template>
  <f7-popup class="categoryselect-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation("LBL_SELECT_CATEGORY") }} </f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar v-model:value="searchValue" :disable-button="false" :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')" :clear-button="true"></f7-searchbar>

      <div class="category-container">
        <template v-for="(category, ind) in categoryList" :key="'ps_' + ind">
          <CategoryCardComponent :data="category" type="select" @selected="selectCategory" />
        </template>
      </div>

      <f7-button class="reset-button" fill large @click="resetSelection">{{ $t.getTranslation("LBL_RESET") }}</f7-button>
    </f7-page>
  </f7-popup>
</template>

<script>
import { ref, onMounted, inject, watch, defineAsyncComponent } from "vue";
import { f7 } from "framework7-vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import CategoryCardComponent from "@/components/cards/CategoryCardComponent.vue";

const CategoryCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "category-card" */ /* webpackMode: "lazy" */ "@/components/cards/CategoryCardComponent.vue"));

export default {
  name: "CategorySelectionPopUpComponent",
  components: {
    CategoryCardComponent,
  },
  props: {
    open: Boolean,
  },
  emits: ["opened", "closed", "selected", "cleared"],
  setup(props, { emit }) {
    const store = useStore();

    const $t = inject("$translation");

    let size = 30;
    let page = 1;
    let lastPage = 1;

    const categoryList = ref([]);
    const showPreloader = ref(false);
    const searchValue = ref("");

    const getCategoryList = async () => {
      let ret = await get("/category/list", { LanguageCode: $t.getLanguage(), page, size, SearchField: "MULTIPLE", SearchType: "LIKE", SearchValue: searchValue.value });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          categoryList.value.push(item);
        }

        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage > page ? getCategoryList() : false;
    };

    onMounted(getCategoryList);

    watch(searchValue, (newValue, oldValue) => {
      categoryList.value = [];
      page = 1;
      getCategoryList();
    });

    const openPopup = () => {
      emit("opened", true);
    };

    const closePopup = () => {
      emit("closed", true);
    };

    const selectCategory = (data) => {
      f7.popup.close();
      emit("selected", data);
    };

    const resetSelection = (data) => {
      f7.popup.close();
      emit("cleared");
    };

    return {
      openPopup,
      closePopup,
      categoryList,
      selectCategory,
      resetSelection,
      loadMore,
      showPreloader,
      searchValue,
      store
    };
  },
};
</script>

<style scoped>
.categoryselect-popup.popup .navbar .title {
  margin-left: 8px;
}
.categoryselect-popup .category-container {
  width: calc(100% - 40px);
  margin: 20px auto;
}
.categoryselect-popup .category-container .category-card {
  width: 100%;
}
.categoryselect-popup .reset-button {
  width: calc(100% - 40px);
  margin: 20px auto;
}
</style>
